import React, { Component } from "react";

import FormErrors from './formErrors'
import ArticleForm from './articleForm'

import HomepageImage1 from "../../assets/images/homepage-image1.jpg";
import HomepageImage2 from "../../assets/images/homepage-image2.jpg";

import "../../assets/styles/components/form.css";

class Form extends Component {
    constructor (props) {
        super(props);
            
        this.state = {
            fields: {
                personOfInterest: ''
            },
            errors: {},
            obituaryFormValid: false
        }

        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleValidation() {
        const correctPerson = ['David P. Cartwright', 'David P Cartwright', 'David Cartwright', 'david cartwright', 'DAVID CARTWRIGHT'];

        let fields = this.state.fields,
            errors = {},
            nameField = fields["personOfInterest"],
            formIsValid = true;

        if (nameField === '') {
            formIsValid = false;
            errors["personOfInterest"] = "Person of interest cannot be empty";
        }    

        if (nameField !== '' && !correctPerson.includes(nameField)) {
            formIsValid = false;
            errors["personOfInterest"] = "No records match this name";
        }

        this.setState({ errors: errors });
        
        return formIsValid;
    }

    handleUserInput(e){    		
        let fields = this.state.fields;

        fields[e.target.name] = e.target.value;

        this.setState({ fields });
    }

    handleFormSubmit(e) {
        e.preventDefault();

        if (this.handleValidation()) {
            this.setState({ articleFormValid: true })
            return true;
        } else {
            this.setState({ articleFormValid: false })
            return false;
        }
    }

    render() {
        const { articleFormValid } = this.state;

        if (!articleFormValid) {
            return (
                <>
                <div className="images">
                    <img src={HomepageImage1} alt="Home 1" />
                    <img src={HomepageImage2} alt="Home 2" />
                </div>
                <div className="text">
                    <h2>Welcome</h2>
                    <p>Welcome to the archive of the Morning Gazette Newspaper, which was founded in 1674 and ceased printing in 1951. Please use the search function below to look through our records. We have a full selection of articles on this online resource but if you should fail to find what you are looking for please feel free to visit our London archive in person, or write to us at our head office.</p>
                </div>
                <form className={`form ${!articleFormValid ? 'has-error' : ''}`} onSubmit={(e) => this.handleFormSubmit(e)} noValidate>
                    <h2>Search Archive</h2>
                    <div className="form-group">
                        <label htmlFor="personOfInterest">Person of Interest:</label>
                        <input type="text" required className="form-control" name="personOfInterest" aria-label="personOfInterest" onChange={this.handleUserInput} />
                    </div>
                    <div className="form-actions">
                        <button type="submit" className="button">Search</button>
                        <FormErrors formErrors={this.state.errors} />
                    </div>
                </form>
                </>
            )
        } else {
            return (
                <ArticleForm />
            )
        }
    }
}

export default Form;